import React from "react";
import '../CSS/mainhead.css';
import Typewriter from 'typewriter-effect';

const Mainhead = () => {

    return (

        <div className="mainhead">

            <div className="desc">

                {/* <h2>Hi, I am Yousuf. <br /> A full-stack developer, passionate programmer and a wannabe entrepreneur.</h2> */}
                <h2>Hi, I am Yousuf. <br />Full-Stack developer.</h2>

                <div className="codetype">
                    <p><Typewriter
                        options={{
                            //strings: ['printf("Hello C");', 'echo "Hello PHP";', 'console.log("Hello JavaScript");', 'print("Hello Python")'],
                            strings : ['printf("Hello C");', 'console.log("Hello JavaScript");', 'echo "Hello PHP";', 'print("Hello Python")'],
                            autoStart: true,
                            loop: true,
                        }}
                    /></p>
                </div>

            </div>

            <div className="yheading">
                 <h1>Y</h1>
            </div>



        </div>
    )
}

export default Mainhead;