import React from "react";
import Contact from "./Contact";
import ScrollAnimation from 'react-animate-on-scroll';
import phplogo from '../images/php.svg';
import laravellogo from '../images/laravel.svg';
import jslogo from '../images/js.svg';
import reactlogo from '../images/react.svg';
import pylogo from '../images/python.svg';
import chatbotimg from '../images/chatbot.png';
import wpappimg from '../images/wpapp2.png';
import gymlanding from '../images/gymlanding.png';
import githublogo from '../images/github.svg';
import expressjs from '../images/expressjs.svg';
import nodejs from '../images/nodejs.png';

import opncricImg from '../images/opncric.png';
import cricgreenImg from '../images/cricgreen.png';
import sayallmeImg from '../images/sayallme.png';
import adminpanelImg from '../images/adminpanel.png';

import wpcmntbotImg from '../images/wpcmntbot.png';
import proxybotImg from '../images/proxybot.jpg';
import googlerankerbotImg from '../images/googlerankerbot.png';

import tvappImg from '../images/tvapp.jpg';

import phpchatmanImg from '../images/phpchatman.png';


const Card = ({ img, name }) => {

    return (

        <div className="card">
            <img src={img} alt="" />
            {name}
        </div>
    );
};

const Skillcard = () => {
    return (

        <div className="cardscontainer">

        {
        // 

        //     <div className="card">
        //         <div className="htmlcss">
        //             <img src={htmllogo} alt="" />
        //             <img src={csslogo} alt="" />
        //         </div>
        //     HTML & CSS
        // </div>
        }

            <Card name="JavaScript" img={jslogo} />
            <Card name="React" img={reactlogo} />
            <Card name="Node JS" img={nodejs} />
            <Card name="Express JS" img={expressjs} />
            <Card name="PHP" img={phplogo} />
            <Card name="Laravel" img={laravellogo} />
            <Card name="Python" img={pylogo} />
            <Card name="React Native" img={reactlogo} />

        </div>


    );
};

const ProjectCard = ({ desc, img }) => {
    return (

        <div className="projectcard">
            <div className="project-img" style={{backgroundImage : `url('${img}')`}}></div>
            {/* <img src={img} loading="lazy" alt=""/> */}
            <p dangerouslySetInnerHTML={{ __html: desc }}>
            </p>
        </div>
    );
};

const Projects = () => {

    const opncricDesc = `OPnCric.com is a cricket niche website, which provides latest cricket scores, news, stats and many more.
    It has modern and clean design. It is made with PHP. It provides real time live cricket scores | 
    Live at : <a href="https://opncric.com" target="_blank"/>OPnCric.com</a>`;

    const cricgreenDesc = `CricGreen.com provides latest cricket scores, news, stats and many more.
    It has modern, clean and simple user interface. It is made with Next JS. It provides real time live cricket scores | 
    Live at : <a href="https://www.cricgreen.com" target="_blank"/>CricGreen.com</a>`;
    
    const sayallmeDesc = `SayAll.me is an anonymous messaging platform. User can send messages and feedbacks to other users without telling their identity.
    It is made with Next JS and Express JS.
    | Live at : <a href="https://sayall.me" target="_blank"/>SayAll.me</a>`;

    const adminpanelDesc = `Laravel Multipurpose Admin Panel. It can be used as an admin panel for mobile apps and as an CMS for websites. 
    The user interface is clean and simple. As the code is written in PHP Laravel, it is very easy to maintain and customize.`;

    const wpcomntbotDesc = `WordPress Auto Comments Bot is a program that can post comments on different WordPress websites in few minutes. 
    It is written in Python. The user interface is created with the PyQT library.`;

    const googlerankerbotDesc = `Google Keyword Ranker Bot is a program that can help in ranking a keyword of a website.
    The working of this program is simple, it goes to google, writes the provided keyword, and searches the given link. It will keep searching the link until it finds it on google. When it finds it, it will click on it and wait a few minutes on the link.
    It is written in Python. The user interface is created with the Tkinter Python library.`;

    const proxybotDesc = `Proxy Scraper is a program that can scrape thousands of free proxies from different sources in a few seconds.
    It is written in Python. The user interface is created with Tkinter python library.`;

    const tvappDesc = `React Native Live Streaming App. It can be used as a live streaming TV / IPTV app. The admin panel is written in PHP Laravel. 
    The design of the app is simple and clean. OneSignal, Admob / StartApp Ads, Firebase Analytics, Video Player with UA support, Feature Slider, 
    Full-Screen Mode, Search, and Multiple Live Links support are the features of this app.<br><br>`;

    const phpchatmanDesc = `PHP Chatman Library . Chatman is a machine-learning based chatbot library build in PHP.
    It can generate responses by learning the conversations. Chatman is very easy to use. 
    It can be used for generating auto responses for social media, customer support, ecommerce website, personal fun etc. 
    I made it with php-ml library.<a href="https://github.com/yousufnoor5/chatman" 
    target="_blank"/> Github</a>`;

    const chatbotdesc = `JavaScript chatbot that can speak and chat.
    It also uses Google to search information. 
    Not just that it can do mathematic calculations, find images, videos, songs, and tell live cricket scores, 
    The Backend is written in PHP. <a href="https://github.com/yousufnoor5/Super-Chatbot-2.0-JS" 
    target="_blank"/>Github</a><br><br><br>`;
    
    const wpappdesc = `React Native Wordpress app. It uses WP REST API to fetch posts 
    from the wordpress website. It is compatible with all types of wordpress blogs/news sites.
    Search, Categories, Shimmer loading, OneSignal, Admob Ads features are available in this app. <a href="https://github.com/yousufnoor5/react-native-wordpress-app" 
    target="_blank"/>Github</a>`;

    const gymlandingdesc = `A Simple & Clean GYM landing page made with HTML, CSS.
    <br>Check my all landing pages : <a href="https://github.com/search?q=user%3Ayousufnoor5+landing" 
    target="_blank"/>Github Landing Pages</a>`;

    return (

        <div className="projectcontainer">

            <ProjectCard desc={opncricDesc} img={opncricImg} />
            <ProjectCard desc={cricgreenDesc} img={cricgreenImg} />
            <ProjectCard desc={sayallmeDesc} img={sayallmeImg} />
            <ProjectCard desc={adminpanelDesc} img={adminpanelImg} />

            <ProjectCard desc={chatbotdesc} img={chatbotimg} />  
            <ProjectCard desc={phpchatmanDesc} img={phpchatmanImg} />         
            
            {/* PYTHON */}
            <ProjectCard desc={wpcomntbotDesc} img={wpcmntbotImg} />
            <ProjectCard desc={proxybotDesc} img={proxybotImg} />
            <ProjectCard desc={googlerankerbotDesc} img={googlerankerbotImg} />

            <ProjectCard desc={tvappDesc} img={tvappImg} />


            <ProjectCard desc={wpappdesc} img={wpappimg} />
            <ProjectCard desc={gymlandingdesc} img={gymlanding} />
          

        </div>

    );
}

const About = () => {
    return (
        <div className="aboutme">

            <p>Hi, I am Yousuf and a full-stack developer who loves making complex things simple.
              I love exploring new technologies and developing them into websites and apps. I build web apps with React, Next JS, PHP & Node JS. 
              <br/><br/> I am currently a student of Computer Science. 
              I'm passionate about writing clean, modular, scalable code. I have a strong background in full-stack development. 
              I am also a fast learner and take great pride in my work.
              I always try to develop something unique and better with my skills.
              <br /> <br />Other than programming I love playing cricket, video games, and reading history books. I also write programming articles on <a target="_blank" href="https://helpincoding.com">helpincoding.com</a></p>
        </div>
    )
}

const Footer = () => {
    return (
        <footer>
           <a href="https://github.com/yousufnoor5" target="_blank" rel="noreferrer"><img src={githublogo} alt="" /></a> 
            <p>© 2021 Muhammad Yousuf Noor.</p>
        </footer>
    )
}

const Main = () => {

    return (
        <div className="container">

            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <h1 id="firstheading" >My Expertise</h1>
                <Skillcard />
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <h1 className="projectheading" id="projects">Projects</h1>
                <Projects />
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <h1 className="aboutheading">About Me</h1>
                <About />
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn" animateOnce>
                <Contact />
            </ScrollAnimation>

            <Footer />




        </div>

    )
}

export default Main;