import React, {useEffect, useState} from "react";
import DarkModeToggle from "react-dark-mode-toggle";
import '../CSS/header.css';

const Header = () => {

    const checkTheme = localStorage.getItem("theme") ? localStorage.getItem("theme") : "";
    const body = document.querySelector("body");

    const setTheme = (e) =>  localStorage.setItem("theme", e);

    //check if dark mode value stored in local storage
    const [isDarkMode, setIsDarkMode] = useState(()=>{

        if (checkTheme=="dark"){
            body.classList.add("dark");
            return true;
        }else{
            return false;
        }
    });
  
    useEffect(()=>{

        console.log()

        if (isDarkMode == true){

            body.classList.add("dark");
            setTheme("dark")

        }else{

            if (body.classList.value.includes("dark")){
            
                body.classList.remove("dark");
                setTheme("light")
            
            }
        }

    },[isDarkMode])

    return (

        <div className="head">

        <div className="menu">
            <ul>
                <li><a href="#" >Home</a></li>
                <li><a href="#projects" >Projects</a></li>
                <li><a href="#contact" >Contact</a></li>
            </ul>
        </div>


        <div className="darkbtn">
            <DarkModeToggle
                onChange={setIsDarkMode}
                checked={isDarkMode}
                size={50}
                speed={6}
             />
             </div>

        </div>
    )
}

export default Header;