import React from 'react';
import '../CSS/contact.css';


const Contact = () => {
    return ( 

        <div className="contact" id="contact">

          <h1>Contact Me</h1>
          <p>Get in touch via email : <a href="mailto:contactyousuf5@gmail.com">contactyousuf5@gmail.com</a></p>
        </div>
    );
};

export default Contact;