import './CSS/App.css';
import Header from "./components/Header";
import Mainhead from "./components/Mainhead";
import Main from "./components/Main";

function App() {
    return ( 
        <>
        <div className="main">

              <Header />
              <Mainhead />
              <Main />

        </div>
        </>
    );
}

export default App;